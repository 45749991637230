<template>
  <div>
    <base-modal
      size="max-w-6xl"
      :showing="visible && !productBoxModalVisible"
      @close="onClose"
      @opened="onOpened"
    >
      <base-card
        title="Detail Barang"
        description="Berdasarkan data barang yang telah dibuat"
        :with-style="false"
      >
        <div class="border-t pt-5">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.code }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.name }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kategori Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.product_category_name ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kode Supplier</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.supplier_code ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Supplier</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.supplier_name ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Harga Supplier</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.supplier_price | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">P/N</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.is_point ? 'Point' : 'Non Point' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">PV</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.point | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">BV</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.bonus | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Lembaga Ijin</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.license_institution || '-' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status Izin</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    product.data.attributes.license_status ? 'green' : 'red'
                  "
                  >{{
                    product.data.attributes.license_status
                      ? 'Lengkap'
                      : 'Sedang Diproses'
                  }}</base-badge
                >
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">
                Mulai Izin - Akhir Izin
              </dt>
              <dd class="mt-1 flex gap-1 text-sm text-gray-900">
                {{ product.data.attributes.license_start }}
                <span class="font-light text-gray-300">s.d</span>
                {{ product.data.attributes.license_end }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.createdAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">
                Terakhir Diperbarui
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product.data.attributes.updatedAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">QR</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <a
                  href=""
                  class="font-medium text-green-600 hover:text-green-500"
                  @click.prevent="qrModalVisible = true"
                  >Setup QR</a
                >
                <a
                  href=""
                  class="font-medium text-yellow-600 ml-4 hover:text-yellow-500"
                  @click.prevent="onSetupQrBox"
                  >Setup QR Box</a
                >
                <div class="mt-2">
                  <qr-code
                    v-if="product.data.attributes.qrcode"
                    :text="product.data.attributes.qrcode"
                    :size="100"
                  />
                </div>
              </dd>
            </div>

            <datatable
              class="col-span-full"
              :columns="[
                { id: 'area', name: 'Area' },
                {
                  id: 'stockist_price',
                  name: 'Harga Stockist',
                  theadClass: 'text-right',
                },
                {
                  id: 'member_price',
                  name: 'Harga Mitra Usaha',
                  theadClass: 'text-right',
                },
                {
                  id: 'consument_price',
                  name: 'Harga Konsumen',
                  theadClass: 'text-right',
                },
              ]"
            >
              <template #tbody="{ classes }">
                <tr
                  v-for="(price, index) in productPrices"
                  :key="index"
                  :class="classes.tr"
                >
                  <td :class="[classes.td]">
                    <p class="font-medium text-gray-900">{{ price.area }}</p>
                  </td>
                  <td :class="[classes.td, 'text-right']">
                    {{ price.stockist_price | toCurrency }}
                  </td>
                  <td :class="[classes.td, 'text-right']">
                    {{ price.member_price | toCurrency }}
                  </td>
                  <td :class="[classes.td, 'text-right']">
                    {{ price.consument_price | toCurrency }}
                  </td>
                </tr>
              </template>
            </datatable>

            <div class="rounded-lg border p-4 sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Harga Karyawan</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProductPrice('Wilayah Jawa', 'Karyawan') | toCurrency }}
              </dd>
            </div>
            <div class="rounded-lg border p-4 sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Harga Khusus</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProductPrice('Wilayah Jawa', 'Khusus') | toCurrency }}
              </dd>
            </div>
            <div class="rounded-lg border p-4 sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Harga Free</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProductPrice('Wilayah Jawa', 'Free') | toCurrency }}
              </dd>
            </div>
          </dl>

          <div class="flex justify-end">
            <base-button @click="$emit('close')">Tutup</base-button>
          </div>
        </div>
      </base-card>
    </base-modal>
    <set-product-qr-modal
      :with-camera-scan="false"
      :product="product"
      :visible="qrModalVisible"
      @close="qrModalVisible = false"
      @updated="onQrUpdated"
    />
    <view-product-box-modal :product-id="product.data.id" :product-code="product.data.attributes.code" :visible="productBoxModalVisible" @close="productBoxModalVisible = false" />
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import SetProductQrModal from './set-product-qr-modal.vue';
import ViewProductBoxModal from './view-product-box-modal.vue';
import QrCode from 'vue-qrcode-component';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    QrCode,
    SetProductQrModal,
    ViewProductBoxModal
  },
  props: {
    visible: Boolean,
    productId: String,
  },
  emits: ['close'],
  data() {
    return {
      loading: false,
      product: {
        data: {
          attributes: {
            code: null,
          },
        },
        included: [],
      },
      qrModalVisible: false,
      productBoxModalVisible: false,
      reload: true
    };
  },
  computed: {
    productPrices() {
      return [
        'Wilayah Jawa',
        'Wilayah I',
        'Wilayah II',
        'Wilayah III',
        'Wilayah IV',
      ].map((area) => ({
        area,
        stockist_price: this.getProductPrice(area, 'Stockist'),
        member_price: this.getProductPrice(area, 'Mitra Usaha'),
        consument_price: this.getProductPrice(area, 'Konsumen'),
      }));
    },
  },
  methods: {
    getProductPrice(area, buyerType) {
      const productPrice = this.product.included.find(
        (included) =>
          included.type === 'product-prices' &&
          included.attributes.area === area &&
          included.attributes['buyer-type'] === buyerType
      );

      if (!productPrice) {
        return null;
      }

      return productPrice.attributes.price;
    },
    async onOpened() {
      if (this.reload) {
        this.loading = true;

        const [res, err] = await this.request(
          `/api/v1/products/${this.productId}`,
          {
            params: {
              'fields[products]':
                'code,name,product_category_name,supplier_code,supplier_name,supplier_price,is_point,point,bonus,license_institution,license_status,license_start,license_end,createdAt,updatedAt,qrcode,product-prices',
              include: 'product-prices',
            },
          }
        );

        if (!err) {
          this.product = res;
        }

        this.loading = false;
      }
    },
    onClose() {
      this.reload = true
      this.$emit('close')
    },
    onQrUpdated() {
      this.qrModalVisible = false;

      this.onOpened();
    },
    onSetupQrBox() {
      this.productBoxModalVisible = true
      this.reload = false
    }
  },
};
</script>
